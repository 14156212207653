import React, { useState, useEffect } from "react";

import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  Divider,
} from "@material-ui/core";

import Api from "../services/Api";

import { useAuth0 } from "../react-auth0-spa";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: "600px",
    margin: "auto",
    // textAlign: "center",
    paddingTop: "16px",
  },
}));

/* organizationInfo = {
  address1: str,
  address2: str,
  admins: [userInfo],
  city: str,
  country: str,
  domains: [str],
  name: str,
  phone: str,
  state: str,
  users: [userInfo]
}
*/

export default function UserOrg(props) {
  const classes = useStyles();
  const { isAuthenticated, userInfo } = useAuth0();
  const [organizationInfo, setOrganizationInfo] = useState({});

  useEffect(() => {
    const getOrgData = async () => {
      let orgInfo = await Api.getUserOrg();
      console.log(orgInfo);
      setOrganizationInfo(orgInfo);
    };

    getOrgData();
  }, []);

  const getUserName = (user) => {
    let middleInitial = user.middle ? user.middle.substring(0, 1) : undefined;
    let name = middleInitial
      ? `${user.first} ${middleInitial}. ${user.last}`
      : `${user.first} ${user.last}`;
    return name;
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Typography variant="h4" style={{ textAlign: "center" }}>
          {organizationInfo.name}
        </Typography>
        <Divider />
        <div style={{ margin: "8px" }}>
          <div>Phone: {organizationInfo.phone}</div>
          <div>
            Address: {organizationInfo.address1}
            {organizationInfo.address2 && (
              <>
                <br />
                {organizationInfo.address2}
              </>
            )}
          </div>
          <div>City: {organizationInfo.city}</div>
          <div>State: {organizationInfo.state}</div>
          <div>Country: {organizationInfo.country}</div>
        </div>
        <div style={{ margin: "8px" }}>
          <div>Admins</div>
          <Divider style={{ marginLeft: "-8px", marginRight: "-8px", marginBottom: "2px"}} />
          <Grid container style={{marginBottom: "4px"}}>
            <Grid item xs>
              <b>Name</b>
            </Grid>
            <Grid item xs>
              <b>Email</b>
            </Grid>
          </Grid>
          {organizationInfo.admins &&
            organizationInfo.admins.map((userInfo, index) => {
              return (
                <Grid container key={index}>
                  <Grid item xs>
                    {getUserName(userInfo)}
                  </Grid>
                  <Grid item xs>
                    {userInfo.email}
                  </Grid>
                </Grid>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
}

// class UserOrgComponent extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {};
//   }

//   render = () => {
//     const { classes } = this.props;
//     return (
//
//     )
//   }
// }

// export const UserOrg = withStyles(styles)(withTheme(UserOrgComponent));

// export default UserOrg;
