import React from "react";

import { Button, Typography, Grid, Divider } from "@material-ui/core";

import moment from "moment";

export default function CustomerDisplayPanel(props) {
    const { customerInfo, showTable } = props;
    let isUser = false;
    if (customerInfo.first) {
      isUser = true;
    }
  
    console.log(customerInfo);
  
    const getUserName = (user) => {
      let middleInitial = user.middle ? user.middle.substring(0, 1) : undefined;
      let name = middleInitial
        ? `${user.first} ${middleInitial}. ${user.last}`
        : `${user.first} ${user.last}`;
      return name;
    };
  
    return (
      <div>
        <Button
          onClick={showTable}
          variant="contained"
          color="primary"
          style={{ marginBottom: "8px", marginLeft: "8px" }}
        >
          Return to Subscriptions
        </Button>
        <div style={{ fontSize: "1.25rem", width: "600px", margin: "auto" }}>
          <Typography
            variant="h4"
            style={{ textAlign: "center", marginBottom: "12px" }}
          >
            Customer Info
          </Typography>
          {isUser && (
            <>
              <Grid container wrap style={{ width: "80%" }}>
                <Grid item xs={6}>
                  <b>Name:</b>
                </Grid>
                <Grid item xs={6}>
                  {customerInfo.first} {customerInfo.middle} {customerInfo.last}
                </Grid>
                <Grid item xs={6}>
                  <b>Email:</b>
                </Grid>
                <Grid item xs={6}>
                  {customerInfo.email}
                </Grid>
                <Grid item xs={6}>
                  <b>First Login:</b>
                </Grid>
                <Grid item xs={6}>
                  {moment(customerInfo.firstLogin).format("YYYY-MM-DD HH:mm")}
                </Grid>
                <Grid item xs={6}>
                  <b>Last Login:</b>
                </Grid>
                <Grid item xs={6}>
                  {moment(customerInfo.lastLogin).format("YYYY-MM-DD HH:mm")}
                </Grid>
                <Grid item xs={6}>
                  <b>Organization:</b>
                </Grid>
                <Grid item xs={6}>
                  {customerInfo.orgName ? customerInfo.orgName : <i>"None"</i>}
                </Grid>
                <Grid item xs={6}>
                  <b>Organization Admin:</b>
                </Grid>
                <Grid item xs={6}>
                  {customerInfo.isOrgAdmin ? "True" : "False"}
                </Grid>
                <Grid item xs={6}>
                  <b>System Admin:</b>
                </Grid>
                <Grid item xs={6}>
                  {customerInfo.isSysAdmin ? "True" : "False"}
                </Grid>
              </Grid>
            </>
          )}
          {!isUser && (
            <>
              <Grid container wrap style={{ width: "80%" }}>
                <Grid item xs={6}>
                  <b>Name:</b>
                </Grid>
                <Grid item xs={6}>
                  {customerInfo.name}
                </Grid>
                <Grid item xs={6}>
                  <b>Phone:</b>
                </Grid>
                <Grid item xs={6}>
                  {customerInfo.phone}
                </Grid>
                <Grid item xs={6}>
                  <b>Address 1:</b>
                </Grid>
                <Grid item xs={6}>
                  {customerInfo.address1}
                </Grid>
                <Grid item xs={6}>
                  <b>Address 2:</b>
                </Grid>
                <Grid item xs={6}>
                  {customerInfo.address2}
                </Grid>
                <Grid item xs={6}>
                  <b>City, State:</b>
                </Grid>
                <Grid item xs={6}>
                  {`${customerInfo.city}, ${customerInfo.state}`}
                </Grid>
                <Grid item xs={6}>
                  <b>Country:</b>
                </Grid>
                <Grid item xs={6}>
                  {customerInfo.country}
                </Grid>
              </Grid>
              <div style={{ marginTop: "16px" }}>
                <div>Admins</div>
                <Divider
                  style={{
                    marginLeft: "-8px",
                    marginRight: "-8px",
                    marginBottom: "2px",
                  }}
                />
                <Grid container style={{ marginBottom: "4px" }}>
                  <Grid item xs>
                    <b>Name</b>
                  </Grid>
                  <Grid item xs>
                    <b>Email</b>
                  </Grid>
                </Grid>
                {customerInfo.admins &&
                  customerInfo.admins.map((userInfo, index) => {
                    return (
                      <Grid container key={index}>
                        <Grid item xs>
                          {getUserName(userInfo)}
                        </Grid>
                        <Grid item xs>
                          {userInfo.email}
                        </Grid>
                      </Grid>
                    );
                  })}
              </div>
              <div style={{ marginTop: "16px" }}>
                <div>Domains</div>
                <Divider
                  style={{
                    marginLeft: "-8px",
                    marginRight: "-8px",
                    marginBottom: "2px",
                  }}
                />
                {customerInfo.domains &&
                  customerInfo.domains.map((domain, index) => {
                    return <div>{domain}</div>;
                  })}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }