import React from "react";

import NavBar from "./NavBar";

function Layout(props) {
  return (
    <div className="flex-fullHeight-container">
      <NavBar />
      <div className="flex-fullHeight-container main-container">
        {props.children}
      </div>
    </div>
  );
}

export default Layout;
