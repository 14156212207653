import React, { Component } from "react";

import {
  withStyles,
  withTheme,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import Api from "../services/Api";

const styles = (theme) => {};

class AdminOverviewComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { subs: [], owners: [] };
  }

  componentDidMount = () => {
    this.getLicenseOverviewData();
  };

  componentDidUpdate = (prevProps, prevState) => {};

  getLicenseOverviewData = async () => {
    let licenses = await Api.getOverview();
    if (licenses == null) {
      return;
    }
    console.log(licenses);

    // let subs = [];
    // let owners = [];
    // for (let i = 0; i < licenses.length; i++) {
    //   let license = licenses[i];
    //   let licenseInfo = license.licenseInfo;
    //   let subIndex = licenseInfo.subscription - 1;
    //   if (subs.length <= subIndex) {
    //     subs[subIndex] = [];
    //     owners[subIndex] = licenseInfo.ownerInfo;
    //   }
    //   subs[subIndex].push(licenseInfo);
    // }
    
    // console.log(subs);
    // console.log(owners);

    // this.setState({ subs: subs, owners: owners });
  };

  render = () => {
    const { classes } = this.props;
    return <div />;
  };
}

export const AdminOverview = withStyles(styles)(
  withTheme(AdminOverviewComponent)
);

export default AdminOverview;
