import React, { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";

import {
  makeStyles,
  withTheme,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Divider,
  Link
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import { useAuth0, Auth0Context } from "../react-auth0-spa";
import Api from "../services/Api.js";
import { userIsOrgAdmin, userIsSysAdmin } from "../utils/utilities";

import { AppBarMenu } from "./AppBarMenu";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  loginButton: {
    color: "white",
  },
  logoutButton: {
    color: "white",
  },
}));

const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const { isAuthenticated, loginWithRedirect, logout, userInfo } = useAuth0();
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);
  const [isSysAdmin, setIsSysAdmin] = useState(false);
  const [userName, setUserName] = useState(undefined);
  const [orgName, setOrgName] = useState(undefined);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar color="primary">
      <Toolbar>
        <Auth0Context.Consumer>
          {({ ...props }) => <AppBarMenu {...props} />}
        </Auth0Context.Consumer>
        <Typography variant="h6" className={classes.title}>
          <Link component={RouterLink} to="/" style={{color: "white"}}>Levrum License Server</Link>
        </Typography>
        {!isAuthenticated && (
          <Button
            className={classes.loginButton}
            onClick={() => loginWithRedirect({})}
          >
            Login
          </Button>
        )}
        {isAuthenticated && (
          <Button className={classes.logoutButton} onClick={() => logout()}>
            Logout
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
