import React, { useState, useEffect } from "react";

import MaskedInput from "react-text-mask";

import {
  makeStyles,
  useTheme,
  Typography,
  Button,
  Paper,
  FormControl,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import DeletableChip from "../components/DeletableChip";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import moment, { max } from "moment";
import MomentUtils from "@date-io/moment";
import Api from "../services/Api";

const states = [
  { name: "Alabama", abbreviation: "AL" },
  { name: "Alaska", abbreviation: "AK" },
  { name: "Arizona", abbreviation: "AZ" },
  { name: "Arkansas", abbreviation: "AR" },
  { name: "California", abbreviation: "CA" },
  { name: "Colorado", abbreviation: "CO" },
  { name: "Connecticut", abbreviation: "CT" },
  { name: "Delaware", abbreviation: "DE" },
  { name: "District of Columbia", abbreviation: "DC" },
  { name: "Florida", abbreviation: "FL" },
  { name: "Georgia", abbreviation: "GA" },
  { name: "Hawaii", abbreviation: "HI" },
  { name: "Idaho", abbreviation: "ID" },
  { name: "Illinois", abbreviation: "IL" },
  { name: "Indiana", abbreviation: "IN" },
  { name: "Iowa", abbreviation: "IA" },
  { name: "Kansas", abbreviation: "KS" },
  { name: "Kentucky", abbreviation: "KY" },
  { name: "Louisiana", abbreviation: "LA" },
  { name: "Maine", abbreviation: "ME" },
  { name: "Maryland", abbreviation: "MD" },
  { name: "Massachusetts", abbreviation: "MA" },
  { name: "Michigan", abbreviation: "MI" },
  { name: "Minnesota", abbreviation: "MN" },
  { name: "Mississippi", abbreviation: "MS" },
  { name: "Missouri", abbreviation: "MO" },
  { name: "Montana", abbreviation: "MT" },
  { name: "Nebraska", abbreviation: "NE" },
  { name: "Nevada", abbreviation: "NV" },
  { name: "New Hampshire", abbreviation: "NH" },
  { name: "New Jersey", abbreviation: "NJ" },
  { name: "New Mexico", abbreviation: "NM" },
  { name: "New York", abbreviation: "NY" },
  { name: "North Carolina", abbreviation: "NC" },
  { name: "North Dakota", abbreviation: "ND" },
  { name: "Ohio", abbreviation: "OH" },
  { name: "Oklahoma", abbreviation: "OK" },
  { name: "Oregon", abbreviation: "OR" },
  { name: "Pennsylvania", abbreviation: "PA" },
  { name: "Rhode Island", abbreviation: "RI" },
  { name: "South Carolina", abbreviation: "SC" },
  { name: "South Dakota", abbreviation: "SD" },
  { name: "Tennessee", abbreviation: "TN" },
  { name: "Texas", abbreviation: "TX" },
  { name: "Utah", abbreviation: "UT" },
  { name: "Vermont", abbreviation: "VT" },
  { name: "Virginia", abbreviation: "VA" },
  { name: "Washington", abbreviation: "WA" },
  { name: "West Virginia", abbreviation: "WV" },
  { name: "Wisconsin", abbreviation: "WI" },
  { name: "Wyoming", abbreviation: "WY" },
];

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(user, admins, theme) {
  return {
    fontWeight:
      admins.indexOf(user) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[1-9]/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

export default function EditOrganizationPanel(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { organizationInfo, hidePanel, add } = props;

  const [name, setName] = useState(organizationInfo.name);
  const [phone, setPhone] = useState(organizationInfo.phone);
  const [address1, setAddress1] = useState(organizationInfo.address1);
  const [address2, setAddress2] = useState(organizationInfo.address2);
  const [city, setCity] = useState(organizationInfo.city);
  const [state, setState] = useState(organizationInfo.state);
  const [country, setCountry] = useState(organizationInfo.country);
  const [domains, setDomains] = useState(organizationInfo.domains);
  const [admins, setAdmins] = useState([]);
  const [newDomain, setNewDomain] = useState("");
  const [newDomainInUse, setNewDomainInUse] = useState(false);
  const [users, setUsers] = useState([]);
  const [enableSave, setEnableSave] = useState(false);
  const [textMask, setTextMask] = useState(organizationInfo.phone);
  let errorDomains = [];

  useEffect(() => {
    const getUsersForOrg = async () => {
      let orgAdmins = organizationInfo.admins;
      let users = await Api.getUsersForOrg(organizationInfo.id);
      let admins = [];
      let adminEmails = [];
      if (users) {
        for (let i = 0; i < orgAdmins.length; i++) {
          adminEmails.push(orgAdmins[i].email);
        }
        for (let i = 0; i < users.length; i++) {
          if (adminEmails.includes(users[i].email)) {
            admins.push(users[i]);
          }
        }
      }
      setAdmins(admins);
      setUsers(users);
    };
    getUsersForOrg();
  }, []);

  useEffect(() => {
    setEnableSave(domains.length > 0 && name && name !== "");
  }, [domains, name]);

  useEffect(() => {
    for (let i = 0; i < domains.length; i++) {
      errorDomains[i] = false;
    }
  }, domains);

  const saveChanges = async () => {
    if (!add) {
      let info = {
        id: organizationInfo.id,
        name: name,
        phone: phone,
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        country: country,
        domains: domains,
        admins: admins,
      };
      await Api.updateOrganization(info);
    } else {
      let info = {
        name: name,
        phone: phone,
        address1: address1,
        addrses2: address2,
        city: city,
        state: state,
        country: country,
        domains: domains,
        admins: admins,
      };
      await Api.addOrganization(info);
    }
    hidePanel();
  };

  const setOrgName = (event) => {
    setName(event.target.value);
  };

  const setOrgPhone = (event) => {
    setPhone(event.target.value);
    setTextMask(event.target.value);
  };

  const setOrgAddress1 = (event) => {
    setAddress1(event.target.value);
  };

  const setOrgAddress2 = (event) => {
    setAddress2(event.target.value);
  };

  const setOrgCity = (event) => {
    setCity(event.target.value);
  };

  const setOrgState = (event) => {
    setState(event.target.value);
  };

  const setOrgCountry = (event) => {
    setCountry(event.target.value);
  };

  const setOrgNewDomain = (event) => {
    setNewDomain(event.target.value);
  };

  const selectedAdminChanged = (event) => {
    setAdmins(event.target.value);
  };

  const removeFromSelectedAdmins = (value) => {
    var newAdmins = admins.slice();
    if (newAdmins.indexOf(value) === -1) return;

    newAdmins.splice(newAdmins.indexOf(value), 1);
    setAdmins(newAdmins);
  };

  const addNewDomain = async () => {
    if (newDomain.trim() === "") {
      return;
    }
    let domainOkay = await Api.isDomainAvailable(newDomain);
    let newDomains = domains.slice();
    if (domainOkay === true) {
      newDomains.push(newDomain);
      setNewDomain("");
      setDomains(newDomains);
      setNewDomainInUse(false);
    } else {
      setNewDomainInUse(true);
    }
  };

  const editDomain = async (event, index) => {
    let newDomain = event.target.value;
    let domainOkay = await Api.isDomainAvailable(newDomain);
    domainOkay = domainOkay || organizationInfo.domains.includes(newDomain);
    if (domainOkay === true) {
      let newDomains = domains.slice();
      newDomains[index] = newDomain;
      setDomains(newDomains);
      errorDomains[index] = false;
    } else {
      errorDomains[index] = true;
    }
  };

  const deleteDomain = async (index) => {
    let newDomains = domains.slice();
    newDomains.splice(index, 1);
    setDomains(newDomains);
  };

  return (
    <div>
      <Button
        onClick={hidePanel}
        variant="contained"
        color="primary"
        style={{ marginBottom: "8px", marginLeft: "8px" }}
      >
        Return to Subscriptions
      </Button>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="h4" style={{ margin: "auto" }}>
          {add ? "Add Organization" : "Edit Organization"}
        </Typography>
      </div>
      <div style={{ margin: "16px" }}>
        <Paper
          style={{
            width: "600px",
            margin: "auto",
            height: "420px",
            padding: "16px",
            position: "relative",
          }}
        >
          <TextField
            id="organizationName"
            label="Organization Name"
            value={name}
            onChange={setOrgName}
            style={{ width: "376px", marginRight: "16px" }}
          />
          <FormControl>
            <InputLabel>Phone Number</InputLabel>
            <Input
              value={textMask}
              onChange={setOrgPhone}
              inputComponent={TextMaskCustom}
              />
          </FormControl>
          {/* <TextField
            id="phone"
            label="Phone Number"
            value={phone}
            onChange={setOrgPhone}
            style={{ width: "204px" }}
          /> */}
          <br />
          <TextField
            id="address1"
            label="Address 1"
            value={address1}
            onChange={setOrgAddress1}
            style={{ width: "290px", marginRight: "16px", marginTop: "16px" }}
          />
          <TextField
            id="address2"
            label="Address 2"
            value={address2}
            onChange={setOrgAddress2}
            style={{ width: "290px", marginTop: "16px" }}
          />
          <br />
          <TextField
            id="city"
            label="City"
            value={city}
            onChange={setOrgCity}
            style={{ width: "180px", marginRight: "16px", marginTop: "16px" }}
          />
          <FormControl
            style={{ width: "180px", marginRight: "16px", marginTop: "16px" }}
          >
            <InputLabel id="stateLabel">State</InputLabel>
            <Select
              labelId="stateLabel"
              id="stateSelect"
              value={state}
              onChange={setOrgState}
            >
              {states.map((state, index) => {
                return (
                  <MenuItem value={state.abbreviation} key={index}>
                    {state.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            id="country"
            label="Country"
            value={country}
            onChange={setOrgCountry}
            style={{ width: "204px", marginTop: "16px" }}
          />
          <Grid
            container
            style={{ width: "100%", marginTop: "8px" }}
            spacing={1}
          >
            <Grid item xs={6}>
              <Typography style={{ marginTop: "16px" }}>Admins</Typography>
              <Divider />
              <Grid
                container
                style={{ width: "100%", marginTop: "12px" }}
                spacing={1}
              >
                <FormControl style={{ width: "100%", marginLeft: "4px" }}>
                  <InputLabel id="adminSelectLabel">Admins</InputLabel>
                  <Select
                    labelId="adminSelectLabel"
                    id="adminSelect"
                    multiple
                    value={admins}
                    onChange={selectedAdminChanged}
                    input={<Input id="adminSelectInput" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected &&
                          selected.map((value) => {
                            return (
                              <DeletableChip
                                key={value.email}
                                label={value.email}
                                className={classes.chip}
                                onDelete={() => {
                                  removeFromSelectedAdmins(value);
                                }}
                              />
                            );
                          })}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {users &&
                      users.map((user) => {
                        return (
                          <MenuItem
                            key={user.email}
                            value={user}
                            style={getStyles(user, admins, theme)}
                          >
                            {user.email}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ marginTop: "16px" }}>Domains</Typography>
              <Divider />
              <Grid
                container
                style={{ width: "100%", marginTop: "8px" }}
                spacing={1}
              >
                {domains.map((domain, index) => {
                  return (
                    <Grid item xs={12}>
                      <div style={{ display: "flex" }}>
                        <TextField
                          id={domain}
                          label="Edit Domain"
                          value={domain}
                          onChange={(event) => {
                            editDomain(event, index);
                          }}
                          style={{ width: "100%", marginRight: "8px" }}
                          error={errorDomains[index]}
                          helperText={
                            errorDomains[index]
                              ? "Domain already in use"
                              : undefined
                          }
                        />
                        <Button
                          onClick={() => {
                            deleteDomain(index);
                          }}
                          style={{ marginTop: "8px", marginBottom: "0px" }}
                        >
                          Delete
                        </Button>
                      </div>
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <div style={{ display: "flex" }}>
                    <TextField
                      id="newDomain"
                      label="Add Domain"
                      value={newDomain}
                      onChange={setOrgNewDomain}
                      style={{ width: "100%", marginRight: "8px" }}
                      error={newDomainInUse}
                      helperText={
                        newDomainInUse ? "Domain already in use" : undefined
                      }
                    ></TextField>
                    <Button
                      onClick={addNewDomain}
                      style={{ marginTop: "8px", marginBottom: "0px" }}
                    >
                      Add
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div style={{ position: "absolute", bottom: "-54px", right: "0px" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={saveChanges}
              disabled={!enableSave}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "8px" }}
              onClick={hidePanel}
            >
              Cancel
            </Button>
            {!enableSave && (
              <div
                style={{ position: "absolute", width: "600px", textAlign: "right", right: "0px", color: "red" }}
              >
                You must enter an Organization name and add one domain to
                continue
              </div>
            )}
          </div>
          {/* </div> */}
        </Paper>
      </div>
      {/* <Dialog open={showConfirmDelete}>
        <DialogTitle>Delete Subscription?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the subscription for {productName}{" "}
            owned by {owner}? This will also deactivate all licenses for the subscription. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={deleteSub}>
            Delete
          </Button>
          <Button variant="contained" color="primary" onClick={hideConfirm}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
}

EditOrganizationPanel.defaultProps = {
  organizationInfo: {
    id: undefined,
    entityId: undefined,
    name: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    admins: [],
    users: [],
    domains: [],
  },
};
