import React, { Component } from "react";

import {
  withStyles,
  withTheme,
  Grid,
  Paper,
  Typography,
  Button,
  TableRow,
  TableCell,
} from "@material-ui/core";

import moment from "moment";

import MUIDataTable from "mui-datatables";
import Api from "../services/Api";

import EditUserPanel from "../components/EditUserPanel";
import Loading from "../components/Loading";

const styles = (theme) => {};

const columns = [
  "Email",
  "First",
  "Middle",
  "Last",
  "Organization",
  "# Licenses",
  "First Login",
  "Last Login",
  "Edit User",
];

class ManageUsersComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      tableShown: true,
      addPanelShown: false,
      editPanelShown: false,
      loading: true,
    };
  }

  componentDidMount = () => {
    this.getUsers();
  };

  getUsers = async () => {
    let users = await Api.getUsers();

    let data = [];
    for (let i = 0; i < users.length; i++) {
      let user = users[i];
      let info = [];
      info.push(user.email);
      info.push(user.first);
      info.push(user.middle);
      info.push(user.last);
      info.push(user.orgName);
      info.push(user.licenses.length);
      info.push(
        user.firstLogin ? moment(user.firstLogin).format("YYYY-MM-DD") : "Never"
      );
      info.push(
        user.lastLogin ? moment(user.lastLogin).format("YYYY-MM-DD") : "Never"
      );
      info.push(i);
      data.push(info);
    }

    this.setState({ users: users, data: data, loading: false });
  };

  options = {
    selectableRows: "none",
    customRowRender: (data) => {
      const [
        email,
        first,
        middle,
        last,
        organization,
        firstLogin,
        lastLogin,
        numLicenses,
        index,
      ] = data;
      return (
        <UserDetailsRow
          key={index}
          classes={this.props.classes}
          email={email}
          first={first}
          middle={middle}
          last={last}
          organization={organization}
          numLicenses={numLicenses}
          firstLogin={firstLogin}
          lastLogin={lastLogin}
          index={index}
          onEdit={this.onEdit}
        />
      );
    },
  };

  onEdit = (index) => {
    this.setState({
      selectedIndex: index,
      editPanelShown: true,
      tableShown: false,
    });
  };

  displayTable = () => {
    this.setState({
      tableShown: true,
      addPanelShown: false,
      editPanelShown: false,
      selectedIndex: undefined,
    });
    this.getUsers();
  };

  render = () => {
    const { classes } = this.props;
    const {
      data,
      tableShown,
      addPanelShown,
      editPanelShown,
      loading,
    } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <>
          <div
            style={{
              height: "100%",
              width: "95%",
              padding: "16px",
              margin: "auto",
            }}
          >
            {tableShown && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: "8px", marginLeft: "8px" }}
                  onClick={() => {
                    this.setState({ addPanelShown: true, tableShown: false });
                  }}
                >
                  Add User
                </Button>
                <MUIDataTable
                  title={"Users"}
                  data={data}
                  columns={columns}
                  options={this.options}
                />
              </>
            )}
            {addPanelShown && (
              <EditUserPanel hidePanel={this.displayTable} add={true} />
            )}
            {editPanelShown && (
              <EditUserPanel
                hidePanel={this.displayTable}
                user={this.state.users[this.state.selectedIndex]}
              />
            )}
          </div>
        </>
      );
    }
  };
}

function UserDetailsRow(props) {
  const {
    email,
    first,
    middle,
    last,
    organization,
    firstLogin,
    lastLogin,
    numLicenses,
    index,
    onEdit,
  } = props;

  const onEditClick = () => {
    if (onEdit) {
      onEdit(index);
    }
  };

  return (
    <TableRow hover key={index}>
      <TableCell size="small">{email}</TableCell>
      <TableCell size="small">{first}</TableCell>
      <TableCell size="small">{middle}</TableCell>
      <TableCell size="small">{last}</TableCell>
      <TableCell size="small">{organization}</TableCell>
      <TableCell size="small">{firstLogin}</TableCell>
      <TableCell size="small">{lastLogin}</TableCell>
      <TableCell size="small">{numLicenses}</TableCell>
      <TableCell size="small">
        <Button style={{ marginLeft: "-6px" }} onClick={onEditClick}>
          Click to Edit
        </Button>
      </TableCell>
    </TableRow>
  );
}

export const ManageUsers = withStyles(styles)(withTheme(ManageUsersComponent));

export default ManageUsers;
