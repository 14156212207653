import React from "react";

import { Link as RouterLink } from "react-router-dom";

import {
  makeStyles,
  Typography,
  Button,
  Divider,
  Grid,
  Link,
} from "@material-ui/core";
import { useAuth0 } from "../react-auth0-spa";

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    textAlign: "center",
    paddingTop: "8px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  },
  container: {
    margin: "auto",
    textAlign: "center",
    paddingTop: "16px",
  },
  loginButton: {
    marginTop: "16px",
    marginBottom: "16px",
  },
  userDiv: {
    marginTop: "16px",
  },
  orgDiv: {
    marginTop: "64px",
  },
  adminDiv: {
    marginTop: "64px",
  },
}));

export const Home = (props) => {
  const classes = useStyles();
  const { isAuthenticated, loginWithRedirect, userInfo } = useAuth0();
  let userIsOrgAdmin = userInfo && userInfo.isOrgAdmin;
  let userIsSysAdmin = userInfo && userInfo.isSysAdmin;
  const firstName = userInfo ? userInfo.first : undefined;
  let orgName = userInfo ? `${userInfo.orgName}` : "";

  return !isAuthenticated ? (
    <React.Fragment>
      <div className={classes.loginContainer}>
        <Typography variant="h4">
          Welcome to the Levrum Licensing Server
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          className={classes.loginButton}
          onClick={() => loginWithRedirect()}
        >
          Login
        </Button>
        <Typography variant="h5">Please login to continue.</Typography>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className={classes.container}>
        {firstName ? (
          <Typography variant="h3">Welcome, {firstName}!</Typography>
        ) : (
          <Typography variant="h3">Welcome!</Typography>
        )}
        <Divider />
        <div className={classes.userDiv}>
          <Grid container spacing={2} style={{ marginTop: "16px" }}>
            <Grid item xs={6}>
              <Typography variant="h6">
                <Link component={RouterLink} to="/licenses">
                  Your Licenses
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">
                <Link component={RouterLink} to="/organization">
                  Your Organization
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </div>
        {userIsOrgAdmin && (
          <div className={classes.orgDiv}>
            <Typography variant="h4">{orgName}</Typography>
            <Divider />
            <Grid container spacing={2} style={{ marginTop: "16px" }}>
              <Grid item xs={6}>
                <Typography variant="h6" style={{ color: "#aaaaaa" }}>
                  {/* <Link component={RouterLink} to="/orglicenses"> */}
                  Manage Licenses
                  {/* </Link> */}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" style={{ color: "#aaaaaa" }}>
                  {/* <Link component={RouterLink} to="/orgusers"> */}
                  Manage Users
                  {/* </Link> */}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
        {userIsSysAdmin && (
          <div className={classes.adminDiv}>
            <Typography variant="h4">System Administration</Typography>
            <Divider />
            <Grid container spacing={2} style={{ marginTop: "16px" }}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  <Link component={RouterLink} to="/activityoverview">
                    View Activity
                  </Link>
                </Typography>
              </Grid>
              {/* <Grid item xs={12}>
              <Typography variant="h6">
                <Link component={RouterLink} to="/adminoverview">
                  Subscription Overview
                </Link>
              </Typography>
            </Grid> */}
              {/* <Grid item xs={6}>
              <Typography variant="h6">
                <Link component={RouterLink} to="/manageproducts">
                  Manage Products
                </Link>
              </Typography>
            </Grid> */}
              <Grid item xs={6}>
                <Typography variant="h6">
                  <Link component={RouterLink} to="/manageusers">
                    Manage Users
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  <Link component={RouterLink} to="/manageorganizations">
                    Manage Organizations
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  <Link component={RouterLink} to="/managesubscriptions">
                    Manage Subscriptions
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Home;
