import React, { Component } from "react";

import {
  withStyles,
  withTheme,
  Grid,
  Paper,
  Typography,
  Button,
  TableRow,
  TableCell,
} from "@material-ui/core";

import MUIDataTable from "mui-datatables";
import moment from "moment";

import Api from "../services/Api";
import EditOrganizationPanel from "../components/EditOrganizationPanel";
import Loading from "../components/Loading";

const styles = (theme) => {};

const columns = [
  "Name",
  "Phone",
  "Address 1",
  "Address 2",
  "City, State",
  "# Users",
  "# Admins",
  "# Domains",
  "Edit Organization",
];

class ManageOrganizationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      tableShown: true,
      addPanelShown: false,
      editPanelShown: false,
      loading: true,
    };
  }

  componentDidMount = () => {
    this.getOrganizations();
  };

  componentDidUpdate = (prevProps, prevState) => {};

  getOrganizations = async () => {
    let orgs = await Api.getOrganizations();

    let data = [];
    let organization, item;
    for (let i = 0; i < orgs.length; i++) {
      organization = orgs[i];
      item = [];
      item.push(organization.name);
      item.push(organization.phone);
      item.push(organization.address1);
      item.push(organization.address2);
      item.push(`${organization.city}, ${organization.state}`);
      item.push(organization.users.length);
      item.push(organization.admins.length);
      item.push(organization.domains.length);
      item.push(i);
      data.push(item);
    }

    this.setState({
      organizations: orgs,
      data: data,
      loading: false,
    });
  };

  onEdit = (index) => {
    this.setState({
      tableShown: false,
      editPanelShown: true,
      selectedIndex: index,
    });
  };

  displayTable = async () => {
    await this.getOrganizations();
    this.setState({
      tableShown: true,
      addPanelShown: false,
      editPanelShown: false,
      selectedIndex: undefined,
    });
  };

  options = {
    selectableRows: "none",
    customRowRender: (data) => {
      const [
        name,
        phone,
        address1,
        address2,
        cityState,
        numUsers,
        numAdmins,
        numDomains,
        index,
      ] = data;
      return (
        <OrganizationDetailsRow
          key={index}
          classes={this.props.classes}
          name={name}
          phone={phone}
          address1={address1}
          address2={address2}
          cityState={cityState}
          numUsers={numUsers}
          numAdmins={numAdmins}
          numDomains={numDomains}
          index={index}
          onEdit={this.onEdit}
        />
      );
    },
  };

  render = () => {
    const { classes } = this.props;
    const {
      tableShown,
      data,
      addPanelShown,
      editPanelShown,
      loading,
    } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <div
          style={{
            height: "100%",
            width: "95%",
            padding: "16px",
            margin: "auto",
          }}
        >
          {tableShown && (
            <>
              <Button
                variant="contained"
                color="primary"
                style={{ marginBottom: "8px", marginLeft: "8px" }}
                onClick={() => {
                  this.setState({ addPanelShown: true, tableShown: false });
                }}
              >
                Add Organization
              </Button>
              <MUIDataTable
                title={"Organizations"}
                data={data}
                columns={columns}
                options={this.options}
              />
            </>
          )}
          {addPanelShown && (
            <EditOrganizationPanel
              hidePanel={this.displayTable}
              add={true}
            ></EditOrganizationPanel>
          )}
          {editPanelShown && (
            <EditOrganizationPanel
              hidePanel={this.displayTable}
              organizationInfo={
                this.state.organizations[this.state.selectedIndex]
              }
            ></EditOrganizationPanel>
          )}
        </div>
      );
    }
  };
}

function OrganizationDetailsRow(props) {
  const {
    classes,
    name,
    phone,
    address1,
    address2,
    cityState,
    numUsers,
    numAdmins,
    numDomains,
    index,
    onEdit,
  } = props;

  const onEditClick = () => {
    if (onEdit) {
      onEdit(index);
    }
  };

  return (
    <TableRow hover key={index}>
      <TableCell size="small">{name}</TableCell>
      <TableCell size="small">{phone}</TableCell>
      <TableCell size="small">{address1}</TableCell>
      <TableCell size="small">{address2}</TableCell>
      <TableCell size="small">{cityState}</TableCell>
      <TableCell size="small">{numUsers}</TableCell>
      <TableCell size="small">{numAdmins}</TableCell>
      <TableCell size="small">{numDomains}</TableCell>
      <TableCell size="small">
        <Button style={{ marginLeft: "-6px" }} onClick={onEditClick}>
          Click to Edit
        </Button>
      </TableCell>
    </TableRow>
  );
}

export const ManageOrganization = withStyles(styles)(
  withTheme(ManageOrganizationComponent)
);

export default ManageOrganization;
