import React, { Component } from "react";

import { withStyles, withTheme, Grid, Paper, Typography } from "@material-ui/core";

const styles = (theme) => {

}

class OrgUsersComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render = () => {
    const { classes } = this.props;
    return (
      <div/>
    )
  }
}

export const OrgUsers = withStyles(styles)(withTheme(OrgUsersComponent));

export default OrgUsers;