import React from "react";
import { Router, Route, Switch } from "react-router-dom";

import history from "./utils/history";

import Layout from "./components/Layout";
import Loading from "./components/Loading";
import { useAuth0 } from "./react-auth0-spa";

import Home from "./views/Home";
import UserLicenses from "./views/UserLicenses";
import UserOrg from "./views/UserOrg";

import OrgLicenses from "./views/OrgLicenses";
import OrgUsers from "./views/OrgUsers";

import ActivityOverview from "./views/ActivityOverview";
import AdminOverview from "./views/AdminOverview";
import ManageProducts from "./views/ManageProducts";
import ManageUsers from "./views/ManageUsers";
import ManageOrganizations from "./views/ManageOrganizations";
import ManageSubscriptions from "./views/ManageSubscriptions";

import "./App.css";

function App(props) {
  const { loading } = useAuth0();

  if (loading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <Layout>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/licenses" exact component={UserLicenses} />
          <Route path="/organization" exact component={UserOrg} />
          <Route path="/orglicenses" exact component={OrgLicenses} />
          <Route path="/orgusers" exact component={OrgUsers} />
          <Route path="/activityoverview" exact component={ActivityOverview} />
          {/* <Route path="/adminoverview" exact component={AdminOverview} /> */}
          <Route path="/manageproducts" exact component={ManageProducts} />
          <Route path="/manageusers" exact component={ManageUsers} />
          <Route path="/manageorganizations" exact component={ManageOrganizations} />
          <Route path="/managesubscriptions" exact component={ManageSubscriptions} />
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
