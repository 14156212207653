import React from "react";
import PropTypes from "prop-types";

import { makeStyles, Paper, Button } from "@material-ui/core";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  license: {
    marginTop: "4px",
    marginBottom: "4px",
    padding: "16px",
    position: "relative",
  },
  licenseLine: {
    paddingTop: "4px",
  },
  deactivateButton: {
    float: "right",
    marginRight: "8px",
    marginTop: "8px",
    position: "absolute",
    right: "8px",
    top: "12px",
  },
}));

// active: true
// email: "kevin.kelly@levrum.com"
// issueDate: "2020-04-16T12:04:26.8549171"
// machineId: null

export default function LicenseInfo(props) {
  const classes = useStyles();
  const { onDeactivateClick } = props;
  let license = props.license || {};
  let issueDate = moment(license.issueDate).format("MMM Do YYYY");

  const deactivateLicense = () => {
    if (onDeactivateClick !== undefined) {
      onDeactivateClick(license);
    }
  };

  return (
    <Paper className={classes.license}>
      <div>
        <b>Email:</b> {license.email}
      </div>
      {license.machineId && (
        <div className={classes.licenseLine}>
          <b>Machine ID:</b> {license.machineId}
        </div>
      )}
      <div className={classes.licenseLine}>
        <b>Issued:</b> {issueDate} (
        {license.active ? (
          <span style={{ color: "green" }}>Active</span>
        ) : (
          <span style={{ color: "red" }}>Inactive</span>
        )}
        )
      </div>
      {onDeactivateClick !== undefined && license && license.active && (
        <Button
          color="primary"
          variant="contained"
          className={classes.deactivateButton}
          onClick={deactivateLicense}
        >
          Deactivate
        </Button>
      )}
    </Paper>
  );
}

LicenseInfo.propTypes = {
  license: PropTypes.object.isRequired,
  onDeactivateClick: PropTypes.func,
};
