import React, { Component } from "react";

import { Link as RouterLink } from "react-router-dom";

import {
  withStyles,
  withTheme,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Link,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import { userIsOrgAdmin, userIsSysAdmin } from "../utils/utilities";

import Auth0Context from "../react-auth0-spa";

const styles = (theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuIcon: {
    color: "white",
  },
  menuPaper: {
    width: "250px",
    minWidth: "250px",
  },
  boldMenuHeader: {
    fontWeight: "600",
    marginLeft: "12px",
  },
  boldMenuText: {
    fontWeight: "600",
    marginLeft: "12px",
    marginTop: "10px",
  },
});

class AppBarMenuComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState) => {};

  openMenu = (event) => {
    this.setState({ anchorEl: event.target });
  };

  closeMenu = () => {
    this.setState({ anchorEl: undefined });
  };

  render = () => {
    const { anchorEl } = this.state;
    const { classes, isAuthenticated, userInfo } = this.props;
    let userIsOrgAdmin = userInfo && userInfo.isOrgAdmin;
    let userIsSysAdmin = userInfo && userInfo.isSysAdmin;
    let userName = userInfo ? `${userInfo.first} ${userInfo.last}` : "";
    let orgName = userInfo ? `${userInfo.orgName}` : "";

    return (
      <React.Fragment>
        <IconButton
          className={classes.menuButton}
          edge="start"
          aria-label="menu"
          onClick={this.openMenu}
        >
          <MenuIcon className={classes.menuIcon} />
        </IconButton>
        {isAuthenticated && (
          <Menu
            id="loggedin-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.closeMenu}
            classes={{
              paper: classes.menuPaper,
            }}
          >
            <Typography className={classes.boldMenuHeader}>
              {userName}
            </Typography>
            <MenuItem
              onClick={this.closeMenu}
              component={RouterLink}
              to="/licenses"
            >
              Your Licenses
            </MenuItem>
            <MenuItem
              onClick={this.closeMenu}
              component={RouterLink}
              to="/organization"
            >
              Your Organization
            </MenuItem>
            {userIsOrgAdmin && (
              <div>
                <Divider />
                <Typography className={classes.boldMenuText}>
                  {orgName}
                </Typography>
                <MenuItem
                  onClick={this.closeMenu}
                  component={RouterLink}
                  to="/orglicenses"
                  disabled={true}
                >
                  Manage Licenses
                </MenuItem>
                <MenuItem
                  onClick={this.closeMenu}
                  component={RouterLink}
                  to="/orgusers"
                  disabled={true}
                >
                  Manage Users
                </MenuItem>
              </div>
            )}
            {userIsSysAdmin && (
              <div>
                <Divider />
                <Typography className={classes.boldMenuText}>
                  System Admin
                </Typography>
                <MenuItem
                  onClick={this.closeMenu}
                  component={RouterLink}
                  to="/activityoverview"
                >
                  View Activity
                </MenuItem>
                {/* <MenuItem
                  onClick={this.closeMenu}
                  component={RouterLink}
                  to="/adminoverview"
                >
                  View Licenses
                </MenuItem> */}
                {/* <MenuItem
                  onClick={this.closeMenu}
                  component={RouterLink}
                  to="/manageproducts"
                >
                  Manage Products
                </MenuItem> */}
                <MenuItem
                  onClick={this.closeMenu}
                  component={RouterLink}
                  to="/manageusers"
                >
                  Manage Users
                </MenuItem>
                <MenuItem
                  onClick={this.closeMenu}
                  component={RouterLink}
                  to="/manageorganizations"
                >
                  Manage Organizations
                </MenuItem>
                <MenuItem
                  onClick={this.closeMenu}
                  component={RouterLink}
                  to="/managesubscriptions"
                >
                  Manage Subscriptions
                </MenuItem>
              </div>
            )}
          </Menu>
        )}
      </React.Fragment>
    );
  };
}

export const AppBarMenu = withStyles(styles)(withTheme(AppBarMenuComponent));

export default AppBarMenu;
