import React, { useState, useEffect } from "react";

import {
  Typography,
  Button,
  Paper,
  FormControl,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import moment, { max } from "moment";
import MomentUtils from "@date-io/moment";
import Api from "../services/Api";

// {
//   adminsIssueLicenses: false,
//   id: 3,
//   licenseType: "Enterprise",
//   maxActiveLicenses: -1,
//   maxVersion: "",
//   minVersion: "",
//   owner: "Levrum Data Technologies",
//   ownerEntity: 2,
//   productId: "databridge",
//   productName: "DataBridge",
//   remainingLicenses: -1,
//   subscriptionEnd: "2040-04-22T00:00:00",
//   subscriptionStart: "2020-04-22T00:00:00",
//   supportEnd: "2022-01-02T00:00:00"
// }

const licenseTypeNames = ["Enterprise", "Machine", "User"];

export default function EditSubscriptionPanel(props) {
  const { subscriptionInfo, showTable, add } = props;

  const [type, setType] = useState(1);
  const [adminIssue, setAdminIssue] = useState(1);
  const [maxLicenses, setMaxLicenses] = useState(-1);
  const [maxMajor, setMaxMajor] = useState(0);
  const [maxMinor, setMaxMinor] = useState(0);
  const [maxPatch, setMaxPatch] = useState(0);
  const [maxBuild, setMaxBuild] = useState(0);
  const [minMajor, setMinMajor] = useState(0);
  const [minMinor, setMinMinor] = useState(0);
  const [minPatch, setMinPatch] = useState(0);
  const [minBuild, setMinBuild] = useState(0);
  const [externalLink, setExternalLink] = useState("");
  const [remainingLicenses, setRemainingLicenses] = useState(0);

  const [owner, setOwner] = useState("");
  const [ownerEntity, setOwnerEntity] = useState(-1);
  const [productName, setProductName] = useState("");
  const [productId, setProductId] = useState("code3strategist");
  const [subStart, setSubStart] = useState(moment());
  const [subEnd, setSubEnd] = useState(moment());
  const [supportEndDate, setSupportEndDate] = useState(moment());
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [productInfos, setProductInfos] = useState([]);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const {
      id,
      owner,
      ownerEntity,
      productId,
      productName,
      licenseType,
      externalLink,
      adminsIssueLicenses,
      maxActiveLicenses,
      minVersion,
      maxVersion,
      remainingLicenses,
      subscriptionStart,
      subscriptionEnd,
      supportEnd,
    } = subscriptionInfo;
    setType(licenseTypeNames.indexOf(licenseType) + 1);
    setAdminIssue(adminsIssueLicenses ? 2 : 1);
    setMaxLicenses(maxActiveLicenses);
    setRemainingLicenses(remainingLicenses || 0);
    setOwner(owner);
    setOwnerEntity(ownerEntity);
    setProductId(productId);
    setProductName(productName);
    setExternalLink(externalLink);
    setSubStart(subscriptionStart ? moment(subscriptionStart) : moment());
    setSubEnd(
      subscriptionEnd ? moment(subscriptionEnd) : moment().add(1, "year")
    );
    setSupportEndDate(
      supportEnd ? moment(supportEnd) : moment().add(1, "year")
    );

    if (minVersion && minVersion != "") {
      let minVersionParts = minVersion.split(".");
      setMinMajor(minVersionParts[0]);
      if (minVersionParts.length > 1) {
        setMinMinor(minVersionParts[1]);
      }
      if (minVersionParts.length > 2) {
        setMinPatch(minVersionParts[2]);
      }
      if (minVersionParts.length > 3) {
        setMinBuild(minVersionParts[3]);
      }
    }

    if (maxVersion && maxVersion != "") {
      let maxVersionParts = maxVersion.split(".");
      setMaxMajor(maxVersionParts[0]);
      if (maxVersionParts.length > 1) {
        setMaxMinor(maxVersionParts[1]);
      }
      if (maxVersionParts.length > 2) {
        setMaxBuild(maxVersionParts[2]);
      }
      if (maxVersionParts.length > 3) {
        setMaxPatch(maxVersionParts[3]);
      }
    }

    updateFields();
  }, []);

  const updateFields = async () => {
    let products = await Api.getProducts();
    let orgData = await Api.getOrganizations();
    let userData = await Api.getUsers();

    setProductInfos(products);

    let customers = [];
    for (let i = 0; i < orgData.length; i++) {
      customers.push({ name: orgData[i].name, entityId: orgData[i].entityId });
    }
    for (let i = 0; i < userData.length; i++) {
      customers.push({
        name: userData[i].email,
        entityId: userData[i].entityId,
      });
    }

    setCustomers(customers);
  };

  const changeCustomer = (event) => {
    setOwnerEntity(event.target.value);
  };

  const changeProduct = (event) => {
    setProductId(event.target.value);
  };

  const changeType = (event) => {
    setType(event.target.value);
  };

  const changeAdmin = (event) => {
    setAdminIssue(event.target.value);
  };

  const setMaxVersion = (event) => {
    if (event.target.id == "maxMajor") {
      setMaxMajor(event.target.value);
    } else if (event.target.id == "maxMinor") {
      setMaxMinor(event.target.value);
    } else if (event.target.id == "maxPatch") {
      setMaxPatch(event.target.value);
    } else {
      setMaxBuild(event.target.value);
    }
  };

  const setMinVersion = (event) => {
    if (event.target.id == "minMajor") {
      setMinMajor(event.target.value);
    } else if (event.target.id == "minMinor") {
      setMinMinor(event.target.value);
    } else if (event.target.id == "minPatch") {
      setMinPatch(event.target.value);
    } else {
      setMinBuild(event.target.value);
    }
  };

  const maxLicensesChanged = (event) => {
    setMaxLicenses(event.target.value);
  };

  const saveChanges = async () => {
    let maxVer = `${maxMajor}.${maxMinor}.${maxPatch}.${maxBuild}`;
    let minVer = `${minMajor}.${minMinor}.${minPatch}.${minBuild}`;
    let info = {
      adminsIssueLicenses: adminIssue == 2,
      licenseType: licenseTypeNames[type - 1],
      id: subscriptionInfo.id,
      owner: owner,
      ownerEntity: ownerEntity,
      externalLink: externalLink,
      maxActiveLicenses: maxLicenses,
      maxVersion: maxVer,
      minVersion: minVer,
      productId: productId,
      productName: productName,
      remainingLicenses: remainingLicenses,
      subscriptionStart: subStart.toDate(),
      subscriptionEnd: subEnd.toDate(),
      supportEnd: supportEndDate.toDate(),
    };
    if (add) {
      await Api.addSubscription(info);
    } else {
      await Api.updateSubscription(info);
    }
    showTable();
  };

  const confirmDelete = () => {
    setShowConfirmDelete(true);
  };

  const deleteSub = async () => {
    await Api.deleteSubscription(subscriptionInfo.id);
    showTable();
  };

  const hideConfirm = () => {
    setShowConfirmDelete(false);
  };

  const externalLinkChanged = (event) => {
    setExternalLink(event.target.value);
  };

  return (
    <div>
      <Button
        onClick={showTable}
        variant="contained"
        color="primary"
        style={{ marginBottom: "8px", marginLeft: "8px" }}
      >
        Return to Subscriptions
      </Button>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="h4" style={{ margin: "auto" }}>
          Subscription Overview
        </Typography>
      </div>
      <div style={{ margin: "16px" }}>
        <Paper
          style={{
            width: "600px",
            margin: "auto",
            minHeight: "420px",
            padding: "16px",
          }}
        >
          {add && (
            <>
              <FormControl style={{ width: "300px", marginRight: "16px" }}>
                <InputLabel id="customer-label">Customer</InputLabel>
                <Select
                  labelId="customer-label"
                  id="customer-select"
                  value={ownerEntity}
                  onChange={changeCustomer}
                >
                  {customers.map((customer, index) => {
                    return (
                      <MenuItem key={index} value={customer.entityId}>
                        {customer.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                style={{
                  width: "300px",
                  marginRight: "16px",
                  marginTop: "16px",
                }}
              >
                <InputLabel id="product-label">Product</InputLabel>
                <Select
                  labelId="product-label"
                  id="product-select"
                  value={productId}
                  onChange={changeProduct}
                >
                  {productInfos.map((product, index) => {
                    return (
                      <MenuItem key={index} value={product.productId}>
                        {product.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          )}
          {!add && (
            <>
              <Typography variant="h6">
                <b>Customer Name:</b> {owner}
              </Typography>
              <Typography variant="h6">
                <b>Product:</b> {productName}
              </Typography>
            </>
          )}
          <div style={{ marginTop: "16px" }}>
            <FormControl style={{ width: "180px", marginRight: "16px" }}>
              <InputLabel id="license-type-label">License Type</InputLabel>
              <Select
                labelId="license-type-label"
                id="license-select"
                value={type}
                onChange={changeType}
              >
                <MenuItem value={1}>Enterprise</MenuItem>
                <MenuItem value={2}>Machine</MenuItem>
                <MenuItem value={3}>User</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ width: "180px", marginRight: "16px" }}>
              <InputLabel id="admin-issue-label">
                Admins Issue Licenses
              </InputLabel>
              <Select
                labelId="admin-issue-label"
                id="admin-issue-select"
                value={adminIssue}
                onChange={changeAdmin}
              >
                <MenuItem value={1}>No</MenuItem>
                <MenuItem value={2}>Yes</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="maxLicenses"
              label="Max Licenses"
              value={maxLicenses}
              onChange={maxLicensesChanged}
              type="number"
              style={{ width: "180px" }}
            />
          </div>
          <div style={{ marginTop: "16px" }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                style={{ marginRight: "16px", width: "180px" }}
                clearable
                label="Subscription Start"
                value={subStart}
                onChange={setSubStart}
                format="YYYY-MM-DD"
              />
              <KeyboardDatePicker
                style={{ marginRight: "16px", width: "180px" }}
                clearable
                label="Subscription End"
                value={subEnd}
                onChange={setSubEnd}
                format="YYYY-MM-DD"
              />
              <KeyboardDatePicker
                style={{ width: "180px" }}
                clearable
                label="Support End"
                value={supportEndDate}
                onChange={setSupportEndDate}
                format="YYYY-MM-DD"
              />
            </MuiPickersUtilsProvider>
          </div>
          <div style={{ marginTop: "16px" }}>
            <TextField
              id="externalLink"
              label="External Link"
              value={externalLink}
              onChange={externalLinkChanged}
              style={{ width: "100%", marginRight: "16px" }}
            />
          </div>
          <Typography variant="h6" style={{ marginTop: "16px" }}>
            Max Version
          </Typography>
          <div style={{ marginTop: "8px" }}>
            <TextField
              id="maxMajor"
              label="Major"
              variant="filled"
              value={maxMajor}
              onChange={setMaxVersion}
              type="number"
              style={{ width: "90px", marginRight: "16px" }}
            />
            <TextField
              id="maxMinor"
              label="Minor"
              variant="filled"
              value={maxMinor}
              onChange={setMaxVersion}
              type="number"
              style={{ width: "90px", marginRight: "16px" }}
            />
            <TextField
              id="maxPatch"
              label="Patch"
              variant="filled"
              value={maxPatch}
              onChange={setMaxVersion}
              type="number"
              style={{ width: "90px", marginRight: "16px" }}
            />
            <TextField
              id="maxBuild"
              label="Build"
              variant="filled"
              value={maxBuild}
              onChange={setMaxVersion}
              type="number"
              style={{ width: "90px", marginRight: "16px" }}
            />
          </div>
          <Typography variant="h6" style={{ marginTop: "16px" }}>
            Min Version
          </Typography>
          <div style={{ marginTop: "8px" }}>
            <TextField
              id="minMajor"
              label="Major"
              variant="filled"
              value={minMajor}
              onChange={setMinVersion}
              type="number"
              style={{ width: "90px", marginRight: "16px" }}
            />
            <TextField
              id="minMinor"
              label="Minor"
              variant="filled"
              value={minMinor}
              onChange={setMinVersion}
              type="number"
              style={{ width: "90px", marginRight: "16px" }}
            />
            <TextField
              id="minPatch"
              label="Patch"
              variant="filled"
              value={minPatch}
              onChange={setMinVersion}
              type="number"
              style={{ width: "90px", marginRight: "16px" }}
            />
            <TextField
              id="minBuild"
              label="Build"
              variant="filled"
              value={minBuild}
              onChange={setMinVersion}
              type="number"
              style={{ width: "90px", marginRight: "16px" }}
            />
          </div>
          <div style={{ position: "relative" }}>
            {/* <div style={{ position: "absolute", bottom: "-70px", left: "0px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={confirmDelete}
              >
                Delete
              </Button>
            </div> */}
            <div
              style={{ position: "absolute", bottom: "-70px", right: "0px" }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={saveChanges}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "8px" }}
                onClick={showTable}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Paper>
      </div>
      {/* <Dialog open={showConfirmDelete}>
        <DialogTitle>Delete Subscription?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the subscription for {productName}{" "}
            owned by {owner}? This will also deactivate all licenses for the subscription. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={deleteSub}>
            Delete
          </Button>
          <Button variant="contained" color="primary" onClick={hideConfirm}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
}

EditSubscriptionPanel.defaultProps = {
  subscriptionInfo: {},
};
