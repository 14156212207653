import React, { Component } from "react";

import {
  makeStyles,
  withStyles,
  withTheme,
  Grid,
  Paper,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";

import moment from "moment";

import Api from "../services/Api";

import { Auth0Context } from "../react-auth0-spa";

import SubscriptionInfo from "../components/SubscriptionInfo";

// import { SubscriptionInfo } from "../components/SubscriptionInfo"

const styles = (theme) => ({
  container: {
    margin: "auto",
    textAlign: "center",
    paddingTop: "16px",
  },
  subscriptionList: {
    paddingTop: "8px",
    width: "1274px"
  },
});

class UserLicensesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscriptions: [],
    };
  }

  componentDidMount = () => {
    this.getSubscriptions();
  };

  componentDidUpdate = (prevProps, prevState) => {};

  getSubscriptions = async () => {
    let subscriptions = await Api.getUserSubscriptions();
    this.setState({ subscriptions: subscriptions });
  };

  render = () => {
    const { classes } = this.props;
    const { subscriptions } = this.state;
    return (
      <div className={classes.container}>
        <Typography variant="h4">Your Licenses</Typography>
        <Divider />
        <Grid container spacing={2} className={classes.subscriptionList}>
          {subscriptions.map((sub, index) => {
            return <Grid item xs={6}><SubscriptionInfo sub={sub} key={index} refresh={this.getSubscriptions} /></Grid>;
          })}
        </Grid>
      </div>
    );
  };
}

export const UserLicenses = withStyles(styles)(
  withTheme(UserLicensesComponent)
);

export default UserLicenses;
