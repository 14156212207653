import React, { Component, useState, useEffect } from "react";

import {
  withStyles,
  withTheme,
  Grid,
  Paper,
  Typography,
  TableRow,
  TableCell,
  Button,
  Divider,
} from "@material-ui/core";

import MUIDataTable from "mui-datatables";
import moment from "moment";

import Api from "../services/Api";

import Loading from "../components/Loading";
import AddSubscriptionPanel from "../components/AddSubscriptionPanel";
import EditSubscriptionPanel from "../components/EditSubscriptionPanel";
import CustomerDisplayPanel from "../components/CustomerDisplayPanel";

const styles = (theme) => {};

const columns = [
  "Customer",
  "Product",
  "License Type",
  "Remaining Licenses",
  "Subscription Start",
  "Subscription End",
  "Support End",
  "View Licenses",
  "View Customer",
];

class ManageSubscriptionsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      tableShown: true,
      addPanelShown: false,
      editorShown: false,
      licensesShown: false,
      customerShown: false,
      loading: true,
    };
  }

  componentDidMount = () => {
    this.getSubscriptionOverview();
  };

  componentDidUpdate = (prevProps, prevState) => {};

  getSubscriptionOverview = async () => {
    let input = await Api.getSubscriptionOverview();

    let ownerInfos = [];
    let subscriptionInfos = [];
    let licenseInfos = [];
    let data = [];

    let subscriptionOverview, subscriptionInfo, item;
    for (let i = 0; i < input.length; i++) {
      subscriptionOverview = input[i];
      subscriptionInfo = subscriptionOverview.subscriptionInfo;
      ownerInfos.push(subscriptionOverview.ownerInfo);
      subscriptionInfos.push(subscriptionOverview.subscriptionInfo);
      licenseInfos.push(subscriptionOverview.licenseInfos);
      item = [];
      item.push(subscriptionInfo.owner);
      item.push(subscriptionInfo.productName);
      item.push(subscriptionInfo.licenseType);
      if (subscriptionInfo.remainingLicenses == -1) {
        item.push("∞");
      } else {
        item.push(subscriptionInfo.remainingLicenses);
      }
      item.push(
        moment(subscriptionInfo.subscriptionStart).format("YYYY-MM-DD")
      );
      item.push(moment(subscriptionInfo.subscriptionEnd).format("YYYY-MM-DD"));
      item.push(moment(subscriptionInfo.supportEnd).format("YYYY-MM-DD"));
      item.push(i);
      data.push(item);
    }

    this.setState({
      ownerInfos: ownerInfos,
      subscriptionInfos: subscriptionInfos,
      licenseInfos: licenseInfos,
      data: data,
      loading: false,
    });
  };

  options = {
    selectableRows: "none",
    customRowRender: (data) => {
      const [
        owner,
        product,
        type,
        remaining,
        start,
        end,
        supportEnd,
        index,
      ] = data;
      return (
        <SubscriptionDetailsRow
          key={index}
          classes={this.props.classes}
          owner={owner}
          product={product}
          type={type}
          remaining={remaining}
          start={start}
          end={end}
          supportEnd={supportEnd}
          index={index}
          onEdit={this.onEdit}
          onLicenses={this.onLicenses}
          onOwner={this.onOwner}
        />
      );
    },
  };

  columns = [
    "Customer",
    "Product",
    "Type",
    "Remaining",
    "Subscription Start",
    "Subscription End",
    "Support End",
    "View Licenses",
    "View Customer",
    "Edit Subscription",
  ];

  onEdit = (index) => {
    this.setState({
      tableShown: false,
      editorShown: true,
      activeIndex: index,
    });
  };

  onLicenses = (index) => {
    this.setState({
      tableShown: false,
      licensesShown: true,
      activeIndex: index,
    });
  };

  onOwner = (index) => {
    this.setState({
      tableShown: false,
      customerShown: true,
      activeIndex: index,
    });
  };

  showTable = (index) => {
    this.setState({
      tableShown: true,
      addPanelShown: false,
      editorShown: false,
      licensesShown: false,
      customerShown: false,
      activeIndex: undefined,
    });

    this.getSubscriptionOverview();
  };

  render = () => {
    const { classes } = this.props;
    const {
      data,
      tableShown,
      addPanelShown,
      editorShown,
      licensesShown,
      customerShown,
      loading,
    } = this.state;

    if (loading) {
      return (<Loading/>)
    } else {
      return (
        <div
          style={{
            height: "100%",
            width: "95%",
            padding: "16px",
            margin: "auto",
          }}
        >
          {tableShown && (
            <>
              <Button
                variant="contained"
                color="primary"
                style={{ marginBottom: "8px", marginLeft: "8px" }}
                onClick={() => {
                  this.setState({ addPanelShown: true, tableShown: false });
                }}
              >
                Add Subscription
              </Button>
              <MUIDataTable
                title={"Active Subscriptions"}
                data={data}
                columns={this.columns}
                options={this.options}
              />
            </>
          )}
          {addPanelShown && (
            <EditSubscriptionPanel showTable={this.showTable} add={true} />
          )}
          {editorShown && (
            <EditSubscriptionPanel
              subscriptionInfo={
                this.state.subscriptionInfos[this.state.activeIndex]
              }
              showTable={this.showTable}
            />
          )}
          {licensesShown && (
            <LicensesDisplayPanel
              licenseInfos={this.state.licenseInfos[this.state.activeIndex]}
              showTable={this.showTable}
            />
          )}
          {customerShown && (
            <CustomerDisplayPanel
              customerInfo={this.state.ownerInfos[this.state.activeIndex]}
              showTable={this.showTable}
            />
          )}
        </div>
      );
    }
  };
}

function LicensesDisplayPanel(props) {
  const { licenseInfos, showTable } = props;
  let licenseInfoSample =
    licenseInfos.length >= 1 ? licenseInfos[0] : undefined;
  let title = "No Licenses";
  if (licenseInfoSample) {
    title = `${licenseInfoSample["product"]} licenses for ${licenseInfoSample["owner"]}`;
  }

  let data = [];
  for (let i = 0; i < licenseInfos.length; i++) {
    let info = [];
    info.push(moment(licenseInfos[i].issueDate).format("YYYY-MM-DD"));
    info.push(licenseInfos[i].email);
    info.push(licenseInfos[i].machineId);
    info.push(licenseInfos[i].active);
    info.push(licenseInfos[i].id);
    info.push(i);
    data.push(info);
  }

  const options = {
    selectableRows: "none",
    customRowRender: (data) => {
      const [issued, email, machineId, active, id, index] = data;
      return (
        <LicenseDetailsRow
          key={index}
          issued={issued}
          email={email}
          machineId={machineId}
          active={active ? "True" : "False"}
          licenseId={id}
          index={index}
        />
      );
    },
  };
  const columns = ["Issued", "Email", "Machine ID", "Active"]; //, "Toggle Status"];

  return (
    <div>
      <Button
        onClick={showTable}
        variant="contained"
        color="primary"
        style={{ marginBottom: "8px", marginLeft: "8px" }}
      >
        Return to Subscriptions
      </Button>
      <div>
        <MUIDataTable
          title={title}
          columns={columns}
          data={data}
          options={options}
        />
      </div>
    </div>
  );
}

function LicenseDetailsRow(props) {
  const { issued, email, machineId, active, id, index } = props;
  const onToggle = () => {};
  return (
    <TableRow hover key={index}>
      <TableCell size="small">{issued}</TableCell>
      <TableCell size="small">{email}</TableCell>
      <TableCell size="small">{machineId}</TableCell>
      <TableCell size="small">{active}</TableCell>
      {/* <TableCell size="small">
        <Button style={{ marginLeft: "-8px" }} onClick={onToggle}>Toggle</Button>
      </TableCell> */}
    </TableRow>
  );
}

function SubscriptionDetailsRow(props) {
  const {
    classes,
    owner,
    product,
    type,
    remaining,
    start,
    end,
    supportEnd,
    index,
    onEdit,
    onLicenses,
    onOwner,
  } = props;

  const onEditClick = () => {
    if (onEdit) {
      onEdit(index);
    }
  };

  const onLicensesClick = () => {
    if (onLicenses) {
      onLicenses(index);
    }
  };

  const onOwnerClick = () => {
    if (onOwner) {
      onOwner(index);
    }
  };
  return (
    <TableRow hover key={index}>
      <TableCell size="small">{owner}</TableCell>
      <TableCell size="small">{product}</TableCell>
      <TableCell size="small">{type}</TableCell>
      <TableCell size="small">{remaining}</TableCell>
      <TableCell size="small">{start}</TableCell>
      <TableCell size="small">{end}</TableCell>
      <TableCell size="small">{supportEnd}</TableCell>
      <TableCell size="small">
        <Button style={{ marginLeft: "-8px" }} onClick={onLicensesClick}>
          Click to View
        </Button>
      </TableCell>
      <TableCell size="small">
        <Button style={{ marginLeft: "-8px" }} onClick={onOwnerClick}>
          Click to View
        </Button>
      </TableCell>
      <TableCell size="small">
        <Button style={{ marginLeft: "-6px" }} onClick={onEditClick}>
          Click to Edit
        </Button>
      </TableCell>
    </TableRow>
  );
}

export const ManageSubscriptions = withStyles(styles)(
  withTheme(ManageSubscriptionsComponent)
);

export default ManageSubscriptions;
