import { createMuiTheme } from "@material-ui/core";

export const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#53585a"
    },
    secondary: {
      main: "#b3011e"
    }
  }
});

export default lightTheme;
