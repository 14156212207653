import React, { useState, useEffect } from "react";

import {
  makeStyles,
  useTheme,
  Typography,
  Button,
  Paper,
  FormControl,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import moment from "moment";
import Api from "../services/Api";

import { useAuth0 } from "../react-auth0-spa";

const useStyles = makeStyles((theme) => ({}));

export default function EditUserPanel(props) {
  const classes = useStyles();

  const { userInfo } = useAuth0();
  let userIsOrgAdmin = userInfo ? userInfo.isOrgAdmin : false;
  let userIsSysAdmin = userInfo ? userInfo.isSysAdmin : false;

  const { user, hidePanel, add } = props;

  const [email, setEmail] = useState(user.email);
  const [first, setFirst] = useState(user.first);
  const [middle, setMiddle] = useState(user.middle);
  const [last, setLast] = useState(user.last);
  const [organization, setOrganization] = useState(1);
  const [orgAdmin, setOrgAdmin] = useState(user.isOrgAdmin || false);
  const [sysAdmin, setSysAdmin] = useState(user.isSysAdmin || false);
  const [enableSave, setEnableSave] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState({});

  const [organizations, setOrganizations] = useState([]);
  const [orgsById, setOrgsById] = useState({});
  const [domain, setDomain] = useState("");
  const [orgModifiable, setOrgModifiable] = useState(false);
  const [organizationEnabled, setOrganizationEnabled] = useState(false);

  useEffect(() => {
    const getOrgs = async () => {
      let orgs = await Api.getOrganizations();
      let orgsById = {};
      for (let i = 0; i < orgs.length; i++) {
        let org = orgs[i];
        orgsById[org.id] = org;
      }
      setOrgsById(orgsById);
      setOrganizations(orgs);
      setOrganization(user.orgId);
    };
    getOrgs();
    setOrgModifiable(userInfo.isSysAdmin);
  }, []);

  useEffect(() => {
    let domainFound = false;
    let organization, orgDomain, domainOrg;
    for (let i = 0; i < organizations.length; i++) {
      organization = organizations[i];
      for (let j = 0; j < organization.domains.length; j++) {
        orgDomain = organization.domains[j];
        if (orgDomain === domain) {
          domainOrg = organization;
        }
      }
    }
    if (domainOrg) {
      setOrganization(domainOrg.id);
      setOrganizationEnabled(false);
    } else {
      setOrganizationEnabled(true);
    }
  }, [orgModifiable, domain, organizations]);

  useEffect(() => {
    var email_string_array = email ? email.split("@") : "";
    var domain_string_location = email_string_array.length - 1;
    var final_domain = email_string_array[domain_string_location];
    setDomain(final_domain);
  }, [email]);

  useEffect(() => {
    setEnableSave(
      email && email !== "" && first && first !== "" && last && last !== ""
    );
  }, [email, first, last]);

  const setUserMail = (event) => {
    setEmail(event.target.value);
  };

  const setUserFirst = (event) => {
    setFirst(event.target.value);
  };

  const setUserMiddle = (event) => {
    setMiddle(event.target.value);
  };

  const setUserLast = (event) => {
    setLast(event.target.value);
  };

  const selectedOrgChanged = (event) => {
    setOrganization(event.target.value);
  };

  const orgAdminChanged = (event) => {
    setOrgAdmin(event.target.value);
  };

  const sysAdminChanged = (event) => {
    setSysAdmin(event.target.value);
  };

  const saveChanges = async () => {
    let info = {
      id: user.id,
      email: email,
      first: first,
      middle: middle,
      last: last,
      orgId: Number(organization),
      isOrgAdmin: orgAdmin,
      isSysAdmin: sysAdmin
    };
    console.log(info);
    if (!add) {
      await Api.updateUser(info);
    } else {
      await Api.addUser(info);
    }
    hidePanel();
  };

  const disableOrganization = () => {
    if (disableEmail()) {
      return true;
    }
    if (!orgModifiable) {
      return true;
    }
    if (!organizationEnabled) {
      return true;
    }
    return false;
  };

  const disableEmail = () => {
    return userInfo && user && userInfo.email == user.email;
  };

  return (
    <div>
      <Button
        onClick={hidePanel}
        variant="contained"
        color="primary"
        style={{ marginBottom: "8px", marginLeft: "8px" }}
      >
        Return to Users
      </Button>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="h4" style={{ margin: "auto" }}>
          {add ? "Add User" : "Edit User"}
        </Typography>
      </div>
      <div style={{ margin: "16px" }}>
        <Paper
          style={{
            width: "600px",
            margin: "auto",
            height: "420px",
            padding: "16px",
            position: "relative",
          }}
        >
          <TextField
            id="email"
            label="Email Address"
            value={email}
            onChange={setUserMail}
            style={{ width: "384px", marginRight: "16px" }}
            disabled={disableEmail()}
          />
          <br />
          <TextField
            id="first"
            label="First Name"
            value={first}
            onChange={setUserFirst}
            style={{ width: "184px", marginRight: "16px", marginTop: "16px" }}
          />

          <TextField
            id="middle"
            label="Middle Name"
            value={middle}
            onChange={setUserMiddle}
            style={{ width: "184px", marginRight: "16px", marginTop: "16px" }}
          />
          <TextField
            id="last"
            label="Last Name"
            value={last}
            onChange={setUserLast}
            style={{ width: "184px", marginRight: "16px", marginTop: "16px" }}
          />
          <FormControl style={{ width: "584px", marginTop: "16px" }}>
            <InputLabel id="orgSelectLabel">Organization</InputLabel>
            <Select
              labelId="orgSelectLabel"
              id="orgSelect"
              value={organization}
              onChange={selectedOrgChanged}
              disabled={disableOrganization()}
            >
              {Object.keys(orgsById).map((orgId, index) => {
                let organization = orgsById[orgId];
                return (
                  <MenuItem key={index} value={orgId}>
                    {organization.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {(userIsOrgAdmin || userIsSysAdmin) && (
            <FormControl
              style={{ width: "184px", marginRight: "16px", marginTop: "16px" }}
            >
              <InputLabel id="orgAdminLabel">Organization Admin</InputLabel>
              <Select
                labelId="orgAdminLabel"
                id="orgAdminSelect"
                value={orgAdmin}
                onChange={orgAdminChanged}
                disabled={disableEmail()}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          )}
          {userIsSysAdmin && (
            <FormControl
              style={{
                width: "184px",
                marginRight: "16px",
                marginTop: "16px",
              }}
            >
              <InputLabel id="sysAdminLabel">System Admin</InputLabel>
              <Select
                labelId="sysAdminLabel"
                id="sysAdminSelect"
                value={sysAdmin}
                onChange={sysAdminChanged}
                disabled={disableEmail()}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          )}
          <div style={{ position: "absolute", bottom: "-54px", right: "0px" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={saveChanges}
              disabled={!enableSave}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "8px" }}
              onClick={hidePanel}
            >
              Cancel
            </Button>
            {!enableSave && (
              <div
                style={{
                  position: "absolute",
                  width: "600px",
                  textAlign: "right",
                  right: "0px",
                  color: "red",
                }}
              >
                You must enter an email, first, and last name to continue
              </div>
            )}
          </div>
        </Paper>
      </div>
    </div>
  );
}

EditUserPanel.defaultProps = {
  user: {},
};
