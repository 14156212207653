import React, { useState } from "react";

import {
  makeStyles,
  Paper,
  Typography,
  Divider,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from "@material-ui/core";

import moment from "moment";

import LicenseInfo from "./LicenseInfo";
import Api from "../services/Api";

const useStyles = makeStyles((theme) => ({
  subscription: {
    margin: "8px",
    width: "600px",
    minHeight: "280px",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
  subContentsDiv: {
    padding: "16px",
  },
  subTitleDiv: {
    paddingBottom: "2px",
    display: "flex",
  },
  subIcon: {
    width: "32px",
    height: "32px",
    marginRight: "14px",
  },
  subDetailsDiv: {
    paddingTop: "2px",
  },
  licensesDiv: {
    paddingTop: "8px",
  },
  licenseRowDiv: {
    paddingTop: "4px",
  },
  licenseFlexRowDiv: {
    paddingTop: "4px",
    display: "flex",
  },
  licenseRowRightDiv: {
    paddingLeft: "32px",
  },
  licensesHeader: {
    fontSize: "1.25em",
  },
  getLicenseButton: {
    float: "right",
    marginTop: "16px",
    marginRight: "12px"
  },
}));

// licenseType: "Site"
// licenses: Array(1)
// owner: "You"
// productId: "code3strategist"
// productName: "Code3 Strategist"
// remainingLicenses: -1
// subscriptionEnd: "2040-04-15T00:00:00"
// subscriptionStart: "2020-04-15T00:00:00"
// supportEnd: "2021-01-01T00:00:00"

export default function SubscriptionInfo(props) {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [machineId, setMachineId] = useState("");

  let { sub, refresh } = props;
  sub = sub ? sub : {};

  let subStart = sub.subscriptionStart
    ? moment(sub.subscriptionStart)
    : undefined;
  let subEnd = sub.subscriptionEnd ? moment(sub.subscriptionEnd) : undefined;
  let supportEnd = sub.supportEnd ? moment(sub.supportEnd) : undefined;
  let subStartStr = subStart ? subStart.format("MMM Do YYYY") : "Unknown";
  let subEndStr = subEnd
    ? `${subEnd.format("MMM Do YYYY")} (${subEnd.fromNow()})`
    : "Unknown";
  let supportEndStr = supportEnd
    ? `${supportEnd.format("MMM Do YYYY")} (${supportEnd.fromNow()})`
    : "Unknown";

  let remainingLicenses = sub.remainingLicenses || 0;

  const getLicenseButtonOnClick = async (event) => {
    if (sub.licenseType.toLowerCase() === "machine") {
      setDialogOpen(true);
    } else {
      let requestResult = await Api.getLicense(sub.productId);
      if (requestResult != null) {
        let response = requestResult.encodedLicense;
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${sub.productName} License.llf`);

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        if (refresh) {
          refresh();
        }
      }
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const onMachineIdChange = (event) => {
    setMachineId(event.target.value);
  };

  const getLicenseWithMachineId = async () => {
    let requestResult = await Api.getLicense(sub.productId, machineId);
    if (requestResult != null) {
      let response = requestResult.encodedLicense;
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${sub.productName} License.llf`);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      handleClose();
      if (refresh) {
        refresh();
      }
    }
  };

  const deactivateLicense = async (license) => {
    await Api.deactivateLicense(license.id);
    if (refresh) {
      refresh();
    }
  }

  return (
    <React.Fragment>
      <Paper className={classes.subscription}>
        <div className={classes.subContentsDiv}>
          <div className={classes.subTitleDiv}>
            <img className={classes.subIcon} src={`/${sub.productId}.png`} />
            <Typography variant="h5" className={classes.subTitle}>
              {sub.productName}
            </Typography>
          </div>
          <Divider />
          <div className={classes.subDetailsDiv}>
            <Typography variant="h6">
              Subscription Details{" "}
              {subEnd && subEnd > moment() ? "- Active" : "- Inactive"}
            </Typography>
            <div className={classes.licenseFlexRowDiv}>
              <div>
                <b>Type:</b> {sub.licenseType}
              </div>
              <div className={classes.licenseRowRightDiv}>
                <b>Owner:</b> {sub.owner}
              </div>
            </div>
            <div className={classes.licenseFlexRowDiv}>
              <div>
                <b>Start Date:</b> {subStartStr}
              </div>
              <div className={classes.licenseRowRightDiv}>
                <b>End Date:</b> {subEndStr}
              </div>
            </div>
            <div className={classes.licenseRowDiv}>
              <b>Support Ends:</b> {supportEndStr}
            </div>
            <div className={classes.licenseRowDiv}>
              <b>Licenses Remaining:</b>{" "}
              {sub.remainingLicenses === -1
                ? "Unlimited"
                : sub.remainingLicenses}
            </div>
          </div>
          <div className={classes.licensesDiv}>
            <div className={classes.licensesHeader}>Licenses</div>
            <Divider />
            {sub.licenses &&
              sub.licenses.map((license, index) => {
                return <LicenseInfo license={license} key={index} onDeactivateClick={deactivateLicense}/>;
              })}
            {sub.licenses && sub.licenses.length > 0 && <Divider />}
            <Button
              className={classes.getLicenseButton}
              variant="contained"
              color="secondary"
              disabled={remainingLicenses === 0}
              onClick={getLicenseButtonOnClick}
            >
              Get License
            </Button>
          </div>
        </div>
      </Paper>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Enter Machine ID:</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To download a license of this type, you must enter the Machine ID of
            the computer you wish to license. If you do not have it, click{" "}
            <Link href="/machineid_win.zip">here</Link> to download the Machine
            ID utility for Windows, or{" "}
            <Link href="/machineid_linux.zip">here</Link> for Linux.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="machineId"
            label="Machine ID"
            fullWidth
            variant="filled"
            onChange={onMachineIdChange}
          />
        </DialogContent>
        <DialogActions>
          <div style={{ marginBottom: "16px", marginRight: "16px" }}>
            <Button onClick={handleClose} color="primary" variant="contained">
              Cancel
            </Button>
            <Button
              style={{marginLeft: "8px"}}
              onClick={getLicenseWithMachineId}
              color="secondary"
              variant="contained"
            >
              Get License
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
