import axios from "axios";

const API_BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? "https://api.license.levrum.com/api"
    : "https://api.license.levrum.com/api";

export default class Api {
  static setAuthorizationHeader(jwt) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;

    axios.interceptors.response.use(undefined, function (error) {
      if (error.response.status === 401) {
        window.location.href = "/login";
      }
    });
  }

  // #region User methods
  static async getUserInfo() {
    try {
      let response = await axios.get(`${API_BASE_URL}/user/getInfo`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getUserSubscriptions() {
    try {
      let response = await axios.get(`${API_BASE_URL}/user/getSubscriptions`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getUserOrg() {
    try {
      let response = await axios.get(`${API_BASE_URL}/user/organizationInfo`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  // #endregion

  // #region License methods
  static async getLicense(productId, machineId) {
    try {
      if (machineId === undefined) {
        machineId = "";
      }
      let licenseRequest = {
        productId: productId,
        machineId: machineId
      }
      let response = await axios.put(
        `${API_BASE_URL}/license`, licenseRequest
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async deactivateLicense(licenseId) {
    try {
      let response = await axios.put(
        `${API_BASE_URL}/license/deactivate/${licenseId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  // #endregion

  static async isDomainAvailable(domain) {
    try {
      let response = await axios.get(
        `${API_BASE_URL}/admin/domainAvailable?domain=${domain}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // #region Admin methods
  static async getActivity(pastDays, futureDays) {
    try {
      let response = await axios.get(
        `${API_BASE_URL}/admin/activity?pastDays=${pastDays}&futureDays=${futureDays}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getOverview() {
    try {
      let response = await axios.get(`${API_BASE_URL}/admin/overview`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getLicenseInfo(licenseId) {}

  static async getProducts() {
    try {
      let response = await axios.get(`${API_BASE_URL}/admin/products`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async addProduct(info) {
    try {
      let response = await axios.post(`${API_BASE_URL}/admin/product`, info);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async updateProduct(info) {
    try {
      let response = await axios.put(`${API_BASE_URL}/admin/product`, info);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getUsers() {
    try {
      let response = await axios.get(`${API_BASE_URL}/admin/users`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getUsersForOrg(orgId) {
    try {
      let response = await axios.get(`${API_BASE_URL}/admin/users/organization/${orgId}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async addUser(user) {
    try {
      console.log(user);
      let response = await axios.post(`${API_BASE_URL}/admin/user`, user);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async updateUser(user) {
    try {
      let response = await axios.put(`${API_BASE_URL}/admin/user`, user);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async deleteUser(id) {
    try {
      let response = await axios.delete(`${API_BASE_URL}/admin/delete/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getOrganizations() {
    try {
      let response = await axios.get(`${API_BASE_URL}/admin/organizations`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async addOrganization(info) {
    try {
      let response = await axios.post(`${API_BASE_URL}/admin/organization`, info);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async updateOrganization(info) {
    try {
      let response = await axios.put(`${API_BASE_URL}/admin/organization`, info);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async deleteOrganization(id) {
    try {
      let response = await axios.delete(
        `${API_BASE_URL}/admin/organization/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getSubscriptionOverview() {
    try {
      let response = await axios.get(
        `${API_BASE_URL}/admin/subscriptionOverview`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async addSubscription(info) {
    try {
      let response = await axios.post(
        `${API_BASE_URL}/admin/subscription`,
        info
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async updateSubscription(info) {
    try {
      let response = await axios.put(
        `${API_BASE_URL}/admin/subscription`,
        info
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async deleteSubscription(id) {
    try {
      let response = await axios.delete(
        `${API_BASE_URL}/admin/subscription/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  // #endregion
}
