import React, { Component } from "react";

import {
  withStyles,
  withTheme,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@material-ui/core";

import moment from "moment";

import Api from "../services/Api";

const styles = (theme) => ({
  headerDiv: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "16px",
    textAlign: "center",
  },
  dateRangeButton: { position: "absolute", right: "54px", top: "82px" },
  container: {
    margin: "auto",
    maxWidth: "95%",
    minWidth: "800px",
    minHeight: "800px",
    padding: "16px",
    overflowX: "hidden",
  },
  newUsers: {},
  recentLogins: {},
  recentLicenses: {},
  expiringSubscriptions: {},
  expiringSupport: {},
  panelPaper: {
    width: "100%",
    height: "100%",
  },
  panelContainer: {
    padding: "16px",
  },
  panelSubContainer: {
    paddingTop: "8px",
    paddingBottom: "8px",
    overflowY: "auto",
  },
  panelGrid: {
    width: "98%",
  },
});

class ActivityOverviewComponent extends Component {
  constructor(props) {
    super(props);

    let pastDays = 14;
    let futureDays = 90;

    let storedPastDays = localStorage.getItem("pastDays");
    if (storedPastDays) {
      pastDays = Number(storedPastDays);
    }

    let storedFutureDays = localStorage.getItem("futureDays");
    if (storedFutureDays) {
      futureDays = Number(storedFutureDays);
    }

    this.state = {
      pastDays: pastDays,
      newPastDays: pastDays,
      futureDays: futureDays,
      newFutureDays: futureDays,
      newUsers: [],
      recentLogins: [],
      recentLicenses: [],
      expiringSubscriptions: [],
      expiringSupport: [],
    };
  }

  componentDidMount = () => {
    this.getActivityData();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.pastDays !== prevState.pastDays ||
      this.state.futureDays !== prevState.futureDays
    ) {
      this.getActivityData();
    }
  };

  getActivityData = async () => {
    let data = await Api.getActivity(
      this.state.pastDays,
      this.state.futureDays
    );

    if (data == null) {
      return;
    }

    this.setState({
      newUsers: data.newUsers,
      recentLogins: data.recentLogins,
      recentLicenses: data.recentLicenses,
      expiringSubscriptions: data.expiringSubscriptions,
      expiringSupport: data.expiringSupport,
    });
  };

  getUserName = (user) => {
    let middleInitial = user.middle ? user.middle.substring(0, 1) : undefined;
    let name = middleInitial
      ? `${user.first} ${middleInitial}. ${user.last}`
      : `${user.first} ${user.last}`;
    return name;
  };

  openDialog = () => {
    this.setState({ dialogOpen: true });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  resetDateRange = () => {
    this.setState({
      newPastDays: 14,
      newFutureDays: 90,
    });
  };

  setNewPastDays = (event) => {
    this.setState({
      newPastDays: event.target.value,
    });
  };

  setNewFutureDays = (event) => {
    this.setState({
      newFutureDays: event.target.value,
    });
  };

  saveDateRange = () => {
    this.setState({
      pastDays: this.state.newPastDays,
      futureDays: this.state.newFutureDays,
      dialogOpen: false,
    });

    localStorage.setItem("pastDays", this.state.newPastDays);
    localStorage.setItem("futureDays", this.state.newFutureDays);
  };

  render = () => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.headerDiv}>
          <Typography variant="h4">Activity Overview</Typography>
          <Typography variant="h6">
            From {this.state.pastDays} days ago to {this.state.futureDays} days
            in the future
          </Typography>
          <Button
            variant="contained"
            className={classes.dateRangeButton}
            onClick={this.openDialog}
          >
            Set Date Range
          </Button>
        </div>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={6} className={classes.newUsers}>
            <Paper className={classes.panelPaper}>
              <div className={classes.panelContainer}>
                <Typography variant="h6">New Users</Typography>
                <Divider />
                <Grid container spacing={1} className={classes.panelGrid}>
                  <Grid item xs>
                    <b>Created</b>
                  </Grid>
                  <Grid item xs>
                    <b>Name</b>
                  </Grid>
                  <Grid item xs>
                    <b>Email</b>
                  </Grid>
                </Grid>
                <div className={classes.panelSubContainer}>
                  {this.state.newUsers.map((user, index) => {
                    let firstLogin = moment(user.firstLogin).format(
                      "MM/DD/YYYY HH:mm"
                    );
                    let name = this.getUserName(user);
                    return (
                      <Grid
                        container
                        spacing={1}
                        className={classes.panelGrid}
                        key={index}
                      >
                        <Grid item xs>
                          {firstLogin}
                        </Grid>
                        <Grid item xs>
                          {name}
                        </Grid>
                        <Grid item xs>
                          {user.email}
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} className={classes.recentLogins}>
            <Paper className={classes.panelPaper}>
              <div className={classes.panelContainer}>
                <Typography variant="h6">Recent Logins</Typography>
                <Divider />
                <Grid container spacing={1} className={classes.panelGrid}>
                  <Grid item xs>
                    <b>Login</b>
                  </Grid>
                  <Grid item xs>
                    <b>Name</b>
                  </Grid>
                  <Grid item xs>
                    <b>Email</b>
                  </Grid>
                </Grid>
                <div className={classes.panelSubContainer}>
                  {this.state.recentLogins.map((user, index) => {
                    let lastLogin = moment(user.lastLogin).format(
                      "MM/DD/YYYY HH:mm"
                    );
                    let name = this.getUserName(user);
                    return (
                      <Grid
                        container
                        spacing={1}
                        className={classes.panelGrid}
                        key={index}
                      >
                        <Grid item xs>
                          {lastLogin}
                        </Grid>
                        <Grid item xs>
                          {name}
                        </Grid>
                        <Grid item xs>
                          {user.email}
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.recentLicenses}>
            <Paper className={classes.panelPaper}>
              <div className={classes.panelContainer}>
                <Typography variant="h6">Recent Licenses</Typography>
                <Divider />
                <Grid container spacing={1} className={classes.panelGrid}>
                  <Grid item xs>
                    <b>Issued</b>
                  </Grid>
                  <Grid item xs>
                    <b>Product</b>
                  </Grid>
                  <Grid item xs>
                    <b>Owner</b>
                  </Grid>
                  <Grid item xs>
                    <b>Licensee</b>
                  </Grid>
                  <Grid item xs>
                    <b>Machine ID</b>
                  </Grid>
                </Grid>
                <div className={classes.panelSubContainer}>
                  {this.state.recentLicenses.map((license, index) => {
                    if (!license) {
                      return <div />;
                    }
                    let issueDate = moment(license.issueDate).format(
                      "MM/DD/YYYY HH:mm"
                    );
                    return (
                      <Grid
                        container
                        spacing={1}
                        className={classes.panelGrid}
                        key={index}
                      >
                        <Grid item xs>
                          {issueDate}
                        </Grid>
                        <Grid item xs>
                          {license.product}
                        </Grid>
                        <Grid item xs>
                          {license.owner}
                        </Grid>
                        <Grid item xs>
                          {license.email}
                        </Grid>
                        <Grid item xs>
                          {license.machineId || <i>null</i>}
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} className={classes.expiringSubscriptions}>
            <Paper className={classes.panelPaper}>
              <div className={classes.panelContainer}>
                <Typography variant="h6">Expiring Subscriptions</Typography>
                <Divider />
                <Grid container spacing={1} className={classes.panelGrid}>
                  <Grid item xs>
                    <b>Owner</b>
                  </Grid>
                  <Grid item xs>
                    <b>Product</b>
                  </Grid>
                  <Grid item xs>
                    <b>Date</b>
                  </Grid>
                  <Grid item xs>
                    <b>Expiring</b>
                  </Grid>
                  <Grid item xs>
                    <b>External Link</b>
                  </Grid>
                </Grid>
                <div className={classes.panelSubContainer}>
                  {this.state.expiringSubscriptions.map((sub, index) => {
                    let expiringMoment = moment(sub.subscriptionEnd);
                    let expiringDate = expiringMoment.format("MM/DD/YYYY");
                    let timeLeft = expiringMoment.fromNow();
                    console.log(sub);
                    return (
                      <Grid
                        container
                        spacing={1}
                        className={classes.panelGrid}
                        key={index}
                      >
                        <Grid item xs>
                          {sub.owner}
                        </Grid>
                        <Grid item xs>
                          {sub.productName}
                        </Grid>
                        <Grid item xs>
                          {expiringDate}
                        </Grid>
                        <Grid item xs>
                          {timeLeft}
                        </Grid>
                        <Grid item xs>
                          {sub.externalLink ? (
                            <a href={sub.externalLink} target="_blank">
                              Click Here
                            </a>
                          ) : (
                            <i>None</i>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} className={classes.expiringSupport}>
            <Paper className={classes.panelPaper}>
              <div className={classes.panelContainer}>
                <Typography variant="h6">Expiring Support</Typography>
                <Divider />
                <Grid container spacing={1} className={classes.panelGrid}>
                  <Grid item xs>
                    <b>Owner</b>
                  </Grid>
                  <Grid item xs>
                    <b>Product</b>
                  </Grid>
                  <Grid item xs>
                    <b>Date</b>
                  </Grid>
                  <Grid item xs>
                    <b>Expiring</b>
                  </Grid>
                  <Grid item xs>
                    <b>External Link</b>
                  </Grid>
                </Grid>
                <div className={classes.panelSubContainer}>
                  {this.state.expiringSubscriptions.map((sub, index) => {
                    let expiringMoment = moment(sub.supportEnd);
                    let expiringDate = expiringMoment.format("MM/DD/YYYY");
                    let timeLeft = expiringMoment.fromNow();

                    return (
                      <Grid
                        container
                        spacing={1}
                        className={classes.panelGrid}
                        key={index}
                      >
                        <Grid item xs>
                          {sub.owner}
                        </Grid>
                        <Grid item xs>
                          {sub.productName}
                        </Grid>
                        <Grid item xs>
                          {expiringDate}
                        </Grid>
                        <Grid item xs>
                          {timeLeft}
                        </Grid>
                        <Grid item xs>
                          {sub.externalLink ? (
                            <a href={sub.externalLink} target="_blank">
                              Click Here
                            </a>
                          ) : (
                            <i>None</i>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleClose}
          disableBackdropClick={true}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Configure Display</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the number of days in the past and the number of days
              in the future you would like the page to display. These values
              will be saved for future sessions.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs>
                <TextField
                  autoFocus
                  margin="dense"
                  id="pastDays"
                  name="pastDays"
                  label="Days in Past"
                  type="number"
                  fullWidth
                  value={this.state.newPastDays}
                  onChange={this.setNewPastDays}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  autoFocus
                  margin="dense"
                  id="futureDays"
                  name="futureDays"
                  label="Days in Future"
                  type="number"
                  fullWidth
                  value={this.state.newFutureDays}
                  onChange={this.setNewFutureDays}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={this.resetDateRange}
              variant="contained"
              color="primary"
            >
              Reset
            </Button>
            <Button
              onClick={this.saveDateRange}
              variant="contained"
              color="secondary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
}

export const ActivityOverview = withStyles(styles)(
  withTheme(ActivityOverviewComponent)
);

export default ActivityOverview;
